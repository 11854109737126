<template>
  <div style="height: calc(100vh - 48px)">
    <mx-preloader :loader-status="preloader"/>
<!--    <v-container fluid>-->
<!--      <v-row class="mt-4">-->
<!--        <v-col cols="12" sm="6" md="4" xl="3">-->
<!--          <v-text-field-->
<!--              label="Suche..."-->
<!--              placeholder="Suche..."-->
<!--              v-model="search.search"-->
<!--              solo-->
<!--              clearable-->
<!--              hide-details-->
<!--          ></v-text-field>-->
<!--        </v-col>-->
<!--        <v-col-->
<!--            class="d-flex ml-md-n5"-->
<!--            cols="12"-->
<!--            sm="6"-->
<!--            md="4"-->
<!--            xl="3"-->
<!--        >-->
<!--          <v-select-->
<!--              v-model="search.search_select"-->
<!--              :items="search_from"-->
<!--              label="Search from"-->
<!--              :append-icon="'mdi-chevron-down'"-->
<!--              solo-->
<!--              hide-details-->
<!--              :menu-props="{ bottom: true, offsetY: true }"-->
<!--          ></v-select>-->
<!--          <v-btn-->
<!--              style="padding: 24px 0 25px 0;"-->
<!--              elevation="2"-->
<!--              color="primary"-->
<!--              medium-->
<!--              class="ml-1"-->
<!--          >-->
<!--            <v-icon size="32">mdi-magnify</v-icon>-->
<!--          </v-btn>-->
<!--        </v-col>-->
<!--      </v-row>-->
<!--    </v-container>-->
    <v-container fluid>
      <v-row class="mt-3">
<!--        <v-col cols="12" :md="customer_id === null ? 12 : 12">-->
        <v-col cols="12" md="6">
          <TableFullCustomer @getId="getId"/>
        </v-col>
<!--        <v-col v-if="customer_id !== null" cols="12" md="6">-->
        <v-col cols="12" md="6">
          <CustomerShow :customer_id="customer_id" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

import TableFullCustomer from "@/components/Tables/TableFullCustomer";
import CustomerShow from "@/components/Customer/CustomerShow";

export default {
  components: { TableFullCustomer, CustomerShow },
  data() {
    return {
      preloader: true,
      search: {
        search: null,
        search_select: 'identification'
      },
      search_from: [
        {
          text: 'Identification',
          value: 'identification'
        },
        {
          text: 'First name',
          value: 'first_name'
        },
        {
          text: 'Last name',
          value: 'last_name'
        },
        {
          text: 'Birthday',
          value: 'birthday'
        }
      ],
      customers: [],
      meta: {},
      customer_id: null
    }
  },
  created () {
    // this.$store.dispatch('customers').then(() => {
    //   this.customers = this.$store.getters.customers.data
    //   this.meta = this.$store.getters.customers.meta
    //   this.preloader = false
    // })
    setTimeout(this.mxPreloader, 700)
  },
  methods: {
    getId(id) {
      this.customer_id = id
    },
    mxPreloader() {
      this.preloader = false
    }
  }
}
</script>
